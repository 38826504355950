import Container from "@mui/material/Container";

export const HomeComponent = () => {
  return (
    <Container maxWidth="xl" sx={{ p: 2 }}>
      Welcome to MPortal. Please select a page from the top menu bar.
      <p>
        Test change to sandbox branch.
        </p>
    </Container>
  );
};
