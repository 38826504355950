import React from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";

import * as gc from "./state/global_context";
import * as loginService from "./auth/login_service";

type TimerId = ReturnType<typeof setTimeout>;

type RefreshCredentialContext = {
  globalState: gc.GlobalState;
  globalStateDispatch: React.Dispatch<gc.GlobalAction>;
  timeoutId: TimerId | null;
  setTimeoutId: React.Dispatch<React.SetStateAction<TimerId | null>>;
};

export const checkCredentialValidity = async (
  rcc: RefreshCredentialContext
) => {
  const remainingValidity = await loginService.getRemainingValidity(
    rcc.globalState.auth
  );

  const refreshApiToken = async () => {
    try {
      const response = await rcc.globalState.mportalEndpoint.post(
        "login/refresh"
      );
      rcc.globalStateDispatch({
        type: "SET_CREDENTIALS",
        auth: response.data,
      });
      rcc.setTimeoutId(null);
      console.log(`${Date.now() / 1000} - refreshed auth token`);
    } catch (error) {
      // Failed to refresh token.
    }
  };

  if (remainingValidity <= 0) {
    //      console.log("Credentials have expired");
    rcc.globalStateDispatch({
      type: "SET_CREDENTIALS",
      auth: { username: "", token: "" },
    });
  }

  const secondsUntilRefresh = Math.max(10, remainingValidity - 60);
  //  console.log(
  //    `${Date.now() / 1000} - Seconds until refresh: ${secondsUntilRefresh}. Existing id: ${rcc.timeoutId}`
  //  );

  // Set a timer to refresh credentials
  if (rcc.timeoutId === null) {
    rcc.setTimeoutId(setTimeout(refreshApiToken, secondsUntilRefresh * 1000));
  }
};

export const OfficeMenu = () => {
  const [officeAnchorEl, setOfficeAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  return (
    <div>
      <Button
        color="inherit"
        onClick={(event) => setOfficeAnchorEl(event.currentTarget)}
      >
        Office
      </Button>
      <Menu
        id="menu-office"
        anchorEl={officeAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(officeAnchorEl)}
        onClose={() => setOfficeAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setOfficeAnchorEl(null);
            navigate("/encounters/browse/today");
          }}
        >
          Encounters
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOfficeAnchorEl(null);
            navigate("/front-desk");
          }}
        >
          Front Desk
        </MenuItem>
      </Menu>
    </div>
  );
};

const UserMenu = () => {
  const [globalState, globalStateDispatch] = gc.useGlobalContext();
  const [credentialRefreshTimeoutId, setCredentialRefreshTimeoutId] =
    React.useState<TimerId | null>(null);

  React.useEffect(() => {
    if (credentialRefreshTimeoutId === null) {
      checkCredentialValidity({
        globalState,
        globalStateDispatch,
        timeoutId: credentialRefreshTimeoutId,
        setTimeoutId: setCredentialRefreshTimeoutId,
      });
    }
  });

  const navigate = useNavigate();

  const handleLogout = () => {
    globalStateDispatch({ type: "LOGOUT" });
    if (credentialRefreshTimeoutId !== null) {
      clearTimeout(credentialRefreshTimeoutId);
      setCredentialRefreshTimeoutId(null);
    }
    navigate("/");
    setUserAnchorEl(null);
  };

  const [userAnchorEl, setUserAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  return (
    <div>
      <IconButton
        size="large"
        onClick={(event) => setUserAnchorEl(event.currentTarget)}
        color="inherit"
      >
        <AccountCircle />
        <Typography variant="h6" component="div">
          {globalState.auth.username}
        </Typography>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={userAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(userAnchorEl)}
        onClose={() => setUserAnchorEl(null)}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/about");
            setUserAnchorEl(null);
          }}
        >
          About
        </MenuItem>
      </Menu>
    </div>
  );
};

export const NavigationBar = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Button color="inherit" component={Link} to="/">
          Home
        </Button>
        <OfficeMenu />
        <Box sx={{ flexGrow: 1 }} />
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};
