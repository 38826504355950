import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import * as gc from "../state/global_context";

import { EncounterData } from "./api_types";

interface DeleteEncounterModalProps {
  encounter: EncounterData;
  openModel: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  onEncounterDeleted: (encounterId: number) => void;
}

export const DeleteEncounterModal = ({
  encounter,
  openModel,
  setOpenModal,
  onEncounterDeleted,
}: DeleteEncounterModalProps): JSX.Element => {
  const [globalState] = gc.useGlobalContext();
  const endpoint = globalState.mportalEndpoint;

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    await endpoint.delete(`office/encounters/${encounter.encounterId}`);

    onEncounterDeleted(encounter.encounterId);
    setOpenModal(false);
  };

  return (
    <Modal open={openModel}>
      <Card
        sx={{
          bgcolor: "background.paper",
          position: "absolute",
          top: "20%",
          left: "50%",
          width: "50%",
          transform: "translate(-50%, -20%)",
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Delete Encounter {encounter.encounterId}?
        </Typography>
        <CardActions>
          <Box
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button variant="contained" color="primary" onClick={onSubmit}>
              Delete
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ m: 2 }}
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Modal>
  );
};
