import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import { useNavigate } from "react-router-dom";

import * as gc from "../state/global_context";
import { WaitingPatient, NewWaitingPatient } from "./api_types";
import { WaitingRoom } from "./waiting_room";
import { FindPatientModal } from "../patients/find_patient";
import { PatientRecord } from "../patients/api_types";
import { AddOrEditEncounter } from "./add_or_edit_encounter";

const getWaitingPatientDate = (
  waitingPatients: WaitingPatient[],
  patientId: number
): Date => {
  if (patientId <= 0) {
    return new Date();
  }
  const match = waitingPatients.find((wp) => wp.patientId === patientId);
  return match ? new Date(match.since) : new Date();
};

export const FrontDeskComponent = () => {
  //  const navigate = useNavigate();

  const [globalState] = gc.useGlobalContext();
  const endpoint = globalState.mportalEndpoint;
  const [shouldShowFindPatientModal, setShouldShowFindPatientModal] =
    React.useState<boolean>(false);

  const [selectedPatientId, setSelectedPatientId] = React.useState(-1);
  const [waitingPatients, setWaitingPatients] = React.useState<
    Array<WaitingPatient>
  >([]);
  const [selectedPatientRecord, setSelectedPatientRecord] =
    React.useState<PatientRecord | null>(null);

  const getWaitingPatients = async () => {
    const res = await endpoint.get<Array<WaitingPatient>>(
      "office/waiting-patients"
    );
    if (res.data !== waitingPatients) {
      setWaitingPatients(res.data);
    }
  };

  React.useEffect(() => {
    getWaitingPatients();
  }, []);

  const updateWaitingPatient = async (
    patientId: number,
    update: WaitingPatient
  ) => {
    const res = await endpoint.put<WaitingPatient>(
      `office/waiting-patients/${patientId}`,
      update
    );
    getWaitingPatients();
  };

  /**
   * Called after the user has clicked the "Admit existing patient" button and
   * selected a patient. Admits that patient to the "Waiting room" room.
   */
  const onAdmitPatientSelected = async (pt: PatientRecord) => {
    // Verify that the patient is not already admitted
    if (waitingPatients.find((wp) => wp.patientId === pt.recordno)) {
      console.log(
        `ERROR: ${pt.recordno} ${pt.lastname}, ${pt.firstname} - already admitted`
      );
      return;
    }

    const newWp: NewWaitingPatient = {
      ptLastname: pt.lastname,
      ptFirstname: pt.firstname,
      patientId: pt.recordno,
      status: "WAITING_ROOM",
    };

    console.log(`Admit - ${pt.recordno} ${pt.lastname}, ${pt.firstname}`);

    const res = await endpoint.post<WaitingPatient>(
      `office/waiting-patients`,
      newWp
    );
    getWaitingPatients();
  };

  /**
   * Called when the user clicks on the "Clear Gone List" button. Removes all
   * patients from the "Gone" room.
   */
  const handleClearGoneList = async () => {
    const toClear = waitingPatients.filter((wp) => wp.status === "GONE");
    for (const wp of toClear) {
      const res = await endpoint.delete(
        `office/waiting-patients/${wp.patientId}`
      );
    }
    getWaitingPatients();
  };

  /**
   * Called by WaitingRoom components when the user clicks on and selects a patient.
   */
  const handleSetSelectedPatientId = async (patientId: number) => {
    console.log("handleSetSelectedPatientId", patientId);
    setSelectedPatientId(patientId);
    if (patientId <= 0) {
      setSelectedPatientRecord(null);
    } else {
      const res = await endpoint.get<PatientRecord>(
        `office/patients/${patientId}`
      );
      setSelectedPatientRecord(res.data);
      console.log(res.data);
    }
  };

  const ExamRoomGridItem = ({
    roomId,
    roomName,
  }: {
    roomId: string;
    roomName: string;
  }) => (
    <Grid item xs={12}>
      <WaitingRoom
        waitingPatients={waitingPatients}
        roomId={roomId}
        roomName={roomName}
        minHeight={60}
        updateWaitingPatient={updateWaitingPatient}
        selectedPatientId={selectedPatientId}
        setSelectedPatientId={handleSetSelectedPatientId}
      />
    </Grid>
  );

  return (
    <Container maxWidth="xl">
      <h2>Front Desk</h2>

      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item md={4}>
          <WaitingRoom
            waitingPatients={waitingPatients}
            roomId="WAITING_ROOM"
            roomName="Waiting Room"
            minHeight={500}
            updateWaitingPatient={updateWaitingPatient}
            selectedPatientId={selectedPatientId}
            setSelectedPatientId={handleSetSelectedPatientId}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 1 }}
            onClick={() => setShouldShowFindPatientModal(true)}
          >
            Admit Existing Patient
          </Button>
        </Grid>
        <Grid item md={4}>
          <Grid container item>
            <ExamRoomGridItem roomId="ROOM1" roomName="Room 1" />
            <ExamRoomGridItem roomId="ROOM2" roomName="Room 2" />
            <ExamRoomGridItem roomId="ROOM3" roomName="Room 3" />
            <ExamRoomGridItem roomId="ROOM4" roomName="Room 4" />
            <ExamRoomGridItem roomId="ROOM5" roomName="Room 5" />
          </Grid>
        </Grid>
        <Grid item md={4}>
          <WaitingRoom
            waitingPatients={waitingPatients}
            roomId="GONE"
            roomName="Gone"
            minHeight={500}
            updateWaitingPatient={updateWaitingPatient}
            selectedPatientId={selectedPatientId}
            setSelectedPatientId={handleSetSelectedPatientId}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginTop: 1 }}
            onClick={handleClearGoneList}
          >
            Clear Gone list
          </Button>
        </Grid>
      </Grid>

      <AddOrEditEncounter
        selectedPatientRecord={selectedPatientRecord}
        date={getWaitingPatientDate(waitingPatients, selectedPatientId)}
      />

      <FindPatientModal
        showModal={shouldShowFindPatientModal}
        closeModal={() => setShouldShowFindPatientModal(false)}
        patientConfirmed={onAdmitPatientSelected}
      />
    </Container>
  );
};
