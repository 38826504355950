import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import * as gc from "./state/global_context";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoginComponent } from "./auth/login_component";
import { NavigationBar } from "./navigation_bar";
import { BrowseEncounters } from "./encounters/browse_encounters";
import { EditEncounter } from "./encounters/edit_encounter";
import { FrontDeskComponent } from "./front_desk/front_desk_component";
import { HomeComponent } from "./home/home_component";
import { AboutMPortal } from "./about/about_mportal";
import { ProvidersLoader } from "./providers/providers_loader";

const MainRouter = () => {
  // Routes to define the React Router component routes
  return (
    <div>
      <ProvidersLoader />
      <NavigationBar />
      <Routes>
        <Route path="/" element={<HomeComponent />} />
        <Route path="/encounters/browse/:date" element={<BrowseEncounters />} />
        <Route path="/encounters/edit/:encounterId" element={<EditEncounter />} />
        <Route path="/front-desk" element={<FrontDeskComponent />} />
        <Route path="/about" element={<AboutMPortal />} />
      </Routes>
    </div>
  );
};

const LoginPageOrMainRouter = () => {
  const [globalState] = gc.useGlobalContext();
  return globalState.auth.username ? <MainRouter /> : <LoginComponent />;
};

const App = () => {
  // - LocalizationProvider sets the adpater used by @mui/x-date-picker
  // - gc.GlobalStateProvider provides all components with access to global
  //   state without needing to prop-drill
  // - BrowserRouter to enable React Router component routing
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <gc.GlobalStateProvider reducer={gc.reducer}>
        <BrowserRouter>
          <LoginPageOrMainRouter />
        </BrowserRouter>
      </gc.GlobalStateProvider>
    </LocalizationProvider>
  );
};

export default App;
