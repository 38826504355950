import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as datefns from "date-fns";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import * as gc from "../state/global_context";
import { EncounterData, PtrecordData } from "./api_types";
import { EncounterDetail } from "./encounter_detail";
import { ExportOffslipModal } from "./export_offslip_modal";

//const EncountersContext = React.createContext<EncounterData[]>([]);

interface EncountersDataGridProps {
  encounters: EncounterData[];
  selectEncounter(encounter: EncounterData | null): any;
  showOnlyNoServiceEncounters: boolean;
  //  setSelectedEncounter: React.Dispatch<
  //    React.SetStateAction<EncounterData | null>
  //  >;
}

/**
 * Shows a list of encounters using a DataGrid, with some basic information
 * about each encounter.
 */
const EncountersDataGrid = ({
  encounters,
  selectEncounter,
  showOnlyNoServiceEncounters,
}: EncountersDataGridProps): JSX.Element => {
  const columns: GridColDef[] = [
    { field: "id", headerName: "Encounter ID" },
    /*    { field: "date", headerName: "Date" },*/
    { field: "time", headerName: "Time" },
    { field: "ptLastname", headerName: "Lastname" },
    { field: "ptFirstname", headerName: "Firstname" },
    { field: "patientId", headerName: "Patient ID", type: "number" },
    { field: "providerShortid", headerName: "Provider" },
    { field: "numServices", headerName: "# Services" },
  ];
  const filteredEncounters = showOnlyNoServiceEncounters
    ? encounters.filter((encounter) => encounter.services.length === 0)
    : encounters;
  const rows = filteredEncounters.map((encounter) => ({
    ...encounter,
    id: encounter.encounterId,
    numServices: encounter.services.length
  }));

  const handleSelectionModelChange = (selectionModel: GridSelectionModel) => {
    if (selectionModel.length <= 0) {
      selectEncounter(null);
      return;
    } else {
      const selectedEncounterId = selectionModel[0];
      const selectedEncounter = encounters.find(
        (encounter) => encounter.encounterId === selectedEncounterId
      );
      if (selectedEncounter) {
        selectEncounter(selectedEncounter);
      } else {
        selectEncounter(null);
      }
    }
  };

  return (
    <div style={{ height: "60vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={40}
        rowsPerPageOptions={[40]}
        onSelectionModelChange={handleSelectionModelChange}
      />
    </div>
  );
};

type EncountersRouteParams = {
  date: string;
};

export const BrowseEncounters = () => {
  const navigate = useNavigate();
  const params = useParams<EncountersRouteParams>();
  const initialDate: Date =
    !params.date || params.date === "today"
      ? new Date()
      : datefns.parse(params.date, "y-MM-dd", new Date());

  const [globalState] = gc.useGlobalContext();
  const [encounters, setEncounters] = React.useState<EncounterData[]>([]);
  const [showOnlyNoServiceEncounters, setShowOnlyNoServiceEncounters] =
    React.useState(false);
  const [date, setDate] = React.useState<Date>(initialDate);
  const [selectedEncounter, setSelectedEncounter] =
    React.useState<EncounterData | null>(null);
  const [patientRecord, setPatientRecord] = React.useState<PtrecordData | null>(
    null
  );

  const endpoint = globalState.mportalEndpoint;

  const getEncounters = async (queryDate: Date) => {
    const dateStr = datefns.format(queryDate, "yyyy-MM-dd");
    const res = await endpoint.get<EncounterData[]>("office/encounters", {
      params: {
        dateMinimum: dateStr,
        dateMaximum: dateStr,
        limit: 40,
        offset: 0,
      },
    });
    if (res.data !== encounters) {
      setEncounters(res.data);
    }
  };

  const handleDateChange = (val: Date | null) => {
    if (!val) {
      return;
    }
    const newDate = new Date(val);
    if (isNaN(newDate.getTime())) {
      return;
    }

    setDate(newDate);
    getEncounters(new Date(newDate));

    const newRoute =
      "/encounters/browse/" + datefns.format(newDate, "yyyy-MM-dd");
    navigate(newRoute);
  };

  const handleNextDay = () => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + 1);
    handleDateChange(newDate);
  };

  const handlePrevDay = () => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() - 1);
    handleDateChange(newDate);
  };

  React.useEffect(() => {
    getEncounters(date);
  }, []);

  const selectEncounter = async (encounter: EncounterData | null) => {
    setSelectedEncounter(encounter);

    if (encounter) {
      const res = await endpoint.get<PtrecordData>(
        `office/patients/${encounter.patientId}`
      );
      setPatientRecord(res.data);
    } else {
      setPatientRecord(null);
    }
  };

  const handleEncounterUpdated = async (updatedEncounter: EncounterData) => {
    setEncounters(
      encounters.map((encounter) =>
        encounter.encounterId === updatedEncounter.encounterId
          ? updatedEncounter
          : encounter
      )
    );
    if (
      selectedEncounter &&
      selectedEncounter.encounterId === updatedEncounter.encounterId
    ) {
      selectEncounter(updatedEncounter);
    }
  };

  const handleEncounterDeleted = async (deletedEncounterId: number) => {
    setEncounters(
      encounters.filter(
        (encounter) => encounter.encounterId !== deletedEncounterId
      )
    );

    if (
      selectedEncounter &&
      selectedEncounter.encounterId === deletedEncounterId
    ) {
      selectEncounter(null);
    }
  };

  const [openExportOffslipModal, setOpenExportOffslipModal] =
    React.useState<boolean>(false);
  const handleClickExportOffslip = async () => {
    setOpenExportOffslipModal(true);
    console.log("Export Offslip!");
  };

  return (
    <Container maxWidth="xl">
      <h2>Encounters</h2>
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={12} md={4} container>
          <Button
            variant="outlined"
            onClick={handlePrevDay}
            sx={{ marginRight: 2 }}
          >
            <KeyboardArrowLeftIcon fontSize="large" />
          </Button>
          <DesktopDatePicker
            label="Date"
            inputFormat="iii yyyy-MM-dd"
            //            mask="___ ____-__-__"
            disableMaskedInput={true}
            value={date}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <Button
            variant="outlined"
            onClick={handleNextDay}
            sx={{ marginLeft: 2 }}
          >
            <KeyboardArrowRightIcon fontSize="large" />
          </Button>
        </Grid>
        <Grid item xs={12} md={2} container justifyContent="flex-end">
          <Button
            variant="outlined"
            color="secondary"
            disabled={encounters.length === 0}
            onClick={handleClickExportOffslip}
          >
            Export day to Z:
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={12} md={6}>
          <EncountersDataGrid
            encounters={encounters}
            selectEncounter={selectEncounter}
            showOnlyNoServiceEncounters={showOnlyNoServiceEncounters}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showOnlyNoServiceEncounters}
                  onChange={(event) =>
                    setShowOnlyNoServiceEncounters(event.target.checked)
                  }
                />
              }
              label="Show only encounters with no services"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <EncounterDetail
            encounter={selectedEncounter}
            patientRecord={patientRecord}
            onEncounterUpdated={handleEncounterUpdated}
            onEncounterDeleted={handleEncounterDeleted}
          />
        </Grid>
      </Grid>

      <ExportOffslipModal
        encounters={encounters}
        date={date}
        showModal={openExportOffslipModal}
        closeModal={() => setOpenExportOffslipModal(false)}
      />
    </Container>
  );
};
